import React from 'react';

import { Skeleton } from '@atlaskit/avatar';
import { Flex, xcss } from '@atlaskit/primitives';

const PresenceAvatarGroupPlaceholderStyles = xcss({
	padding: 'space.050',
	marginLeft: 'space.025',
	marginRight: 'space.025',
	minWidth: '36px',
	minHeight: '36px',
});

export const PresenceAvatarGroupPlaceholder = () => {
	return (
		<Flex
			testId="content-header-presence-placeholder"
			justifyContent="center"
			xcss={PresenceAvatarGroupPlaceholderStyles}
		>
			<Skeleton appearance="circle" size="small" />
		</Flex>
	);
};
