import type { CollabEditOptions } from '@atlaskit/editor-common/collab';
import type { ProviderError, CollabEditProvider } from '@atlaskit/collab-provider';

import type { CollabRecoveryErrorType } from './useError';
import type { HeartbeatData } from './useEditorHeartbeat/useEditorHeartbeat';

export enum CollabServiceProperty {
	NCS = 'ncs',
	SYNCHRONY = 'synchrony',
}

export interface CollabEditState {
	collabEditInitialized: boolean;
	collabEditError: ProviderError | null;
	collabEditInternetLost: boolean;
	collabRecoveryError: CollabRecoveryErrorType | Error | null;
	collabEditConnectionTimedOut: boolean;
}

export interface NativeCollabInjectedProps {
	contentId: string;
	contentType: string;
	spaceKey: string;
	spaceId: string | null;
	disabled: boolean;
	internetDisconnected: boolean;
	refetchNativeCollab: (shouldCheckCache: boolean) => Promise<any>;
	setForceDisabled: (forceDisabled: boolean) => void;
	forceDisabled?: boolean;
	draftShareId?: string;
	collabEditProvider: Promise<CollabEditProvider> | undefined;
	collabEdit: CollabEditOptions;
	collabEditState: CollabEditState;
	heartbeatData: HeartbeatData;
	isFirstParticipant: boolean;
	isSingleUserSession: boolean;
	isUnpublishedDraft: boolean;
	title: string | null;
	onTitleChange: (newTitle: string) => void;
	version: number;
	viewMode?: boolean;
	onPublishUpdateTitleInPageTree: () => void;
	subType: string | null;
	isOverviewPage?: boolean;
	createdDate?: string | null;
}
